export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if (!to.params.dinamicListing) return next()
    if (!from.params.dinamicListing) return next()
    if (!Object.keys(to.query).length) return next()
    if (to.params.dinamicListing !== from.params.dinamicListing) {
      const newRoute = {
        ...to,
        query: {}
      }
      next(newRoute)
    } else {
      next()
    }
  })
}
