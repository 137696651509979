// Sync compare/favorites/cart lists logic

import { mapActions, mapGetters } from 'vuex'
import { isCartRoute } from '~~/utils/utils'

export default {
  computed: {
    ...mapGetters({
      allListItems: 'cart/allListItems'
    })
  },

  mounted() {
    this.$root.$once('login-complete', this.onUserLogin)
    this.$root.$once('register-complete', this.onUserRegister)
  },

  methods: {
    ...mapActions({
      pushAllFavoritesItemsToServer: 'favorites/pushAllListItemsToServer',
      pushAllCompareItemsToServer: 'compareList/pushAllListItemsToServer',
      pushAllCartItemsToServer: 'cart/pushAllListItemsToServer',
      fetchLists: 'account/fetchLists',
      getItemsFromBase: 'cart/getItemsFromBase'
    }),

    async onUserLogin() {
      await this.fetchLists()
      await this.pushListsToServer()
      if (isCartRoute(this.$route)) this.fetchCartItems()
    },

    async fetchCartItems() {
      const items = this.allListItems.filter((item) => item.placeholder)
      await this.getItemsFromBase(items)
      this.$root.$emit('loading-stop')
    },

    onUserRegister() {
      this.pushListsToServer()
    },

    async pushListsToServer() {
      await this.pushAllFavoritesItemsToServer()
      await this.pushAllCompareItemsToServer()
      await this.pushAllCartItemsToServer()
    }
  }
}
