export default function ({ $auth, redirect }) {
  if (process.server) return

  $auth.onError((error, name, endpoint) => {
    console.log('Auth Error: ', error)
    if ($auth.loggedIn) {
      $auth.logout()
      redirect('/auth/')
    }
  })

  window.onNuxtReady((app) => {
    const oldLogout = app.$auth.logout.bind(app.$auth)

    app.$auth.logout = (...args) => {
      app.$emit('logout')
      clearUserData(app)

      app.$sweetalert.insert({
        text: app.$t('notifications.loggedOut'),
        timer: 2000,
        toast: true,
        position: 'top-end',
        showConfirmButton: false
      })

      return oldLogout(...args)
    }
  })

  function clearUserData(app) {
    app.$store.dispatch('account/flushAccount')
    app.$store.dispatch('order/flushOrders')
  }
}
