import uniqBy from 'lodash/uniqBy'
import { normRouteName } from '~~/utils/utils'
import {
  description,
  companyName,
  title as postfix,
  omitTitlePostfix
} from '~~/utils/definitions/defaults'

export default ({ app, $axios, route }, inject) => {
  const defaultMeta = [
    {
      property: 'title',
      name: 'title',
      content: postfix
    },
    {
      property: 'description',
      name: 'description',
      content: description
    },
    {
      property: 'name',
      name: 'name',
      content: companyName[process.env.siteName]
    },
    {
      property: 'lang',
      name: 'lang',
      content: app.i18n.locale
    },
    {
      name: 'og:url',
      content: `https://${process.env.prodDomain}${route.path}`
    },
    {
      property: 'og:title',
      name: 'og:title',
      content: postfix
    },
    {
      property: 'og:description',
      name: 'og:description',
      content: description
    },
    {
      property: 'og:type',
      name: 'og:type',
      content: 'website'
    },
    {
      property: 'og:site_name',
      name: 'og:site_name',
      content: postfix
    }
  ]

  const getMetaObject = ([key, value]) => ({
    name: key,
    property: key,
    content: value
  })

  const getOgMetaObject = ([key, value]) => ({
    ...getMetaObject([key, value]),
    name: `og:${key}`,
    property: `og:${key}`
  })

  const getHidMetaObject = ([key, value]) => ({
    hid: key,
    ...getMetaObject([key, value])
  })

  const makeOgTags = (arr) => {
    const tagsToOg = ['title', 'description']
    return arr.reduce((acc, current) => {
      if (tagsToOg.includes(current.name || current.property))
        return [
          ...acc,
          current,
          getOgMetaObject([current.name || current.property, current.content])
        ]
      return [...acc, current]
    }, [])
  }

  /**
   * If there is "og:title" (title received from Yoast plugin in WordPress) meta, we should use it to overwrite other titles
   * @param metas
   * @param route
   * @returns {*}
   */
  const fixTitles = (metas, route) => {
    const ogTitle = metas.find((m) => m.name === 'og:title')
    if (!ogTitle) return metas
    return metas.map((m) => {
      if (['title', 'og:title'].includes(m.name)) {
        const titleTemplate = [ogTitle.content]
        if (!omitTitlePostfix.includes(normRouteName(route)))
          titleTemplate.push(postfix)
        m.content = titleTemplate.filter((t) => t).join(' | ')
        return m
      }
      return m
    })
  }

  const getUnique = (arr) => uniqBy(arr.reverse(), 'name').reverse()

  const createSeo = function (slug, pageMetaArray = []) {
    const seoObj =
      app.i18n.messages[app.i18n.locale].metadata[normRouteName(slug)]?.seo
    const metadata = seoObj
      ? Object.entries(seoObj).reduce((acc, entry) => {
          return [...acc, getMetaObject(entry)]
        }, [])
      : []
    const pageMetaData = makeOgTags(pageMetaArray).map((m) =>
      getHidMetaObject([m.name || m.property, m.content])
    )

    // TODO: remove sapphire when we will be ready to launch production
    if (process.env.isDev || process.env.siteName === 'sapphire')
      defaultMeta.unshift({
        name: 'robots',
        content: 'noindex'
      })

    const metas = getUnique([
      ...makeOgTags(defaultMeta),
      ...makeOgTags(metadata),
      ...pageMetaData
    ])

    return fixTitles(metas, slug)
  }

  inject('createSeo', createSeo)
}
