import axios from 'axios'
import { getHost } from '../utils/config'
import { cookiesOpts } from '~~/utils/definitions/defaults.js'

export default {
  mounted() {
    if (process.env.isDev) return
    this.setCurrentDeployVersion()
    window.addEventListener('focus', this.verifyDeploy, false)
  },

  methods: {
    async setCurrentDeployVersion() {
      const v = await axios.get(`${getHost(process.env.isDev)}/deploy.txt`)
      this.$cookies.set('deploy-version', v.data, cookiesOpts)
    },

    async verifyDeploy() {
      const v = await axios.get(
        `${getHost(process.env.isDev)}/deploy.txt`.replace(/([^:]\/)\/+/g, '$1')
      )

      const deployVersion = this.$cookies.get('deploy-version')

      if (!deployVersion) {
        this.$cookies.set('deploy-version', v.data, cookiesOpts)
        return
      }

      if (
        this.normalizeVersion(deployVersion) !== this.normalizeVersion(v.data)
      ) {
        this.$cookies.set('deploy-version', v.data, cookiesOpts)
        if (process.client) {
          sessionStorage.clear()
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(
                /=.*/,
                '=;expires=' + new Date().toUTCString() + ';path=/'
              )
          })
          this.$cookies.set('deploy-version', v.data, cookiesOpts)
          location.reload(true)
        }
      }
    },

    normalizeVersion(response) {
      if (!response) return ''
      return String(response)
        .replace(/(\r\n|\n|\r)/gm, '')
        .trim()
    }
  }
}
