const primaryColor = {
  ruby: '#bf1c60',
  emerald: '#276D50',
  gemstone: '#5F4ABF',
  sapphire: '#214B96'
}

export default (context, inject) => {
  inject('site', {
    name: process.env.siteName,
    primaryColor: primaryColor[process.env.siteName],
    defaultColor: '#2e2e2e'
  })
}
