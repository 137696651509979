export default ({ redirect, route }) => {
  window.onNuxtReady((app) => {
    app.$root.$on('logout', () => {
      const authRoutes = ['account-change-password', 'account']
      if (
        authRoutes.some((r) => {
          return app.context.route.name.includes(r)
        })
      )
        redirect(app.localePath({ name: 'auth' }))
    })
  })
}
