<template lang="pug">
.site-wrapper
  //.container
  .summary-layout
    TopHeaderOrder
    nuxt
  footer-order(:show-socials="false", :show-address="true")
  auth-modal
</template>

<script>
import { hydrateWhenIdle } from 'vue-lazy-hydration'
import defaultLayout from './default.vue'

export default {
  name: 'SummaryOrderLayout',

  components: {
    AuthModal: hydrateWhenIdle(() => import('~~/components/Auth/AuthModal')),
    TopHeaderOrder: () =>
      import('~~/components/Layout/TopHeader/TopHeaderOrder'),
    FooterOrder: () => import('~~/components/Layout/Footer/FooterOrder')
  },

  extends: defaultLayout
}
</script>

<style lang="sass" scoped>
.summary-layout
  ::v-deep
    .cart-page
      border-top: none
</style>
