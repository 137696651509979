import createPersistedState from 'vuex-persistedstate'
import uniq from 'lodash/uniq'
import * as shvl from 'shvl'
import cloneDeep from 'lodash/cloneDeep'
import { productListTypeByCategory } from '~~/utils/definitions/defaults'

const wishlistHoursExpire = 48

export default ({ store, isHMR, route }) => {
  if (isHMR) return
  window.onNuxtReady((app) => {
    if (process.env.version !== localStorage.getItem('site-version')) {
      clearLocalStorage(store, app, route)
      deleteAllCookies()
      return
    }

    init(store, route)

    removeOldWishlistItems(store)

    setTimeout(() => {
      store.dispatch('favorites/markItemsAsNotSynced')
      store.dispatch('compareList/markItemsAsNotSynced')
      store.dispatch('cart/markItemsAsNotSynced')
      store.dispatch('setFetched')
      app.$root.$emit('store-restored')
    }, 10)

    setTimeout(() => {
      restoreCustomItemData(store)
    }, 300)

    store.dispatch('account/fetchLists')
  })
}

const MS_PER_HOUR = 1000 * 60 * 60

function clearLocalStorage(store, app, route) {
  localStorage.clear()
  localStorage.setItem('site-version', process.env.version)
  init(store, route)
  store.dispatch('setFetched')
  setTimeout(() => {
    app.$root.$emit('store-restored')
  })
}

function removeOldWishlistItems(store) {
  const listsNames = Object.values(productListTypeByCategory)
  listsNames.pop()
  const itemsKeys = uniq(listsNames)
  itemsKeys.forEach((key) => {
    store.getters[`favorites/${key}Items`].forEach((item) => {
      if (!item.addedAt) store.dispatch('favorites/removeFromList', item)
      if (
        dateDiffInDays(item.addedAt, new Date()) > wishlistHoursExpire &&
        !store.state.auth.loggedIn
      )
        store.dispatch('favorites/removeFromList', item)
    })
  })
}

function dateDiffInDays(a, b) {
  return Math.floor((Date.parse(b) - Date.parse(a)) / MS_PER_HOUR)
}

function init(store, route) {
  createPersistedState({
    key: process.env.siteName,
    // do not restore state for shared cart
    reducer: (state, p) => {
      let paths = cloneDeep(p)
      if (route.name?.includes('cart') && route.query.p) {
        paths = paths.filter((path) => path !== 'cart')
      }
      return Array.isArray(paths)
        ? paths.reduce(function (substate, path) {
            return shvl.set(substate, path, shvl.get(state, path))
          }, {})
        : state
    },
    paths: [
      'cart',
      'favorites',
      // 'account',
      'compareList',
      'navigation',
      'shareProduct'
    ]
  })(store)
}

function restoreCustomItemData(store) {
  // Restore customStone and customSetting from sessionStorage
  const customStone = sessionStorage.getItem('customStone')
  const customSetting = sessionStorage.getItem('customSetting')
  if (customStone && customSetting) {
    store.commit('customItem/IS_DIRTY', true)
  }
  if (customStone) {
    store.commit('customItem/SET_CUSTOM_STONE', JSON.parse(customStone))
  }
  if (customSetting) {
    store.commit('customItem/SET_CUSTOM_SETTING', JSON.parse(customSetting))
  }
}

function deleteAllCookies() {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}
