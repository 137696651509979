import get from 'lodash/get'
import { isTokenValid } from '~~/utils/utils'
import { APP } from '~~/store/modules/app'
import LIST from '~~/store/lists/cart/constants'
import addSecurityHeaders from '~~/utils/helpers/securityHeaders.js'

/**
 * We are solving the problem of caching broken pages.
 * Sometimes the backend issues an error, for example,
 * it cannot return filters on the product list page.
 * Such a page should not be cached. Therefore, we are
 * trying to receive it from the server again after 250ms,
 * and if there is still no response, we add the necessary
 * headers to cancel caching of this page on CloudFront.
 */
export default ({ app, $axios, store, req, res }) => {
  let originalRequest = {}

  $axios.onResponseError(async (err) => {
    originalRequest = err.config

    if (originalRequest && !originalRequest._retry) {
      originalRequest._retry = true

      originalRequest.headers.common = {}

      addSecurityHeaders(originalRequest)
      try {
        // Wait 250 ms before retrying
        await new Promise((resolve) => setTimeout(resolve, 250))
        return await $axios(originalRequest)
      } catch (retryError) {
        console.error('Error after retry request:', retryError)
      }
    } else {
      if (process.server && res) {
        // Add caching headers only on the server
        res.setHeader(
          'Cache-Control',
          'no-store, max-age=0, no-cache, must-revalidate'
        )
        res.setHeader('Pragma', 'no-cache')
        res.setHeader('Expires', '0')
      } else {
        // On client, if there was an unsuccessful retry attempt
        console.error('Error after retry request:', err)
      }
      // Return the error for further processing
      return Promise.reject(err)
    }
  })

  $axios.onRequest((request) => {
    // Check if request exists before adding headers
    if (!request) return request
    addSecurityHeaders(request)
    if (!request.headers || !request.headers.common) return request
    request.headers.common['Country-Code'] = store.getters.countryCode

    const token = store.state.auth.access_token
    const tokenExpiration = store.state.auth.tokenExpiration

    if (token && !isTokenValid(tokenExpiration)) {
      app.$auth.logout()
    }
    // Update token axios header
    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`
    }
    return request
  })

  $axios.onError((error) => {
    const safeMessage =
      'Oops! Something went wrong! Please try again, or get in touch if you need help.'
    // handle expired token
    if (
      error.response?.status === 400 &&
      error.response.config.url.endsWith('account/auth')
    ) {
      store.commit(
        `app/${APP.ERROR}`,
        get(error, 'response.data', safeMessage) || safeMessage
      )
    } else if (error.response?.status === 401) {
      app.$auth.logout()
    } else if (error.response?.status === 429) {
      store.commit(`cart/${LIST.BACKEND_TAX_CALC_MODE}`, true)
    } else {
      store.commit(
        `app/${APP.ERROR}`,
        get(error, 'response.data', safeMessage) || safeMessage
      )
      console.error('Axios Error:', error.response?.data || error.message)
      return Promise.reject(error)
    }
  })
}
