<template lang="pug">
div.site-wrapper.is-flex
  top-header-component(:class="{hide}")
  user-menu-mobile.is-hidden-desktop(:show-menu="showUserMenu", @close="onCloseHandler")
  main-menu
  nuxt(ref="page")
  reviews-component(v-if="showReviews")
  footer-component
  lazy-hydrate(when-idle)
    video-modal(:name="modalName", :options="modalOptions")
  auth-modal
  search-modal
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import LazyHydrate, {
  hydrateWhenIdle,
  hydrateWhenVisible
} from 'vue-lazy-hydration'
import listsSyncMixin from '~~/mixins/listsSyncMixin'
import subscriptions from '~~/utils/store/subscriptions'
import { cookiesOpts } from '~~/utils/definitions/defaults'
import { normRouteName } from '~~/utils/sitemapUtils'
import deployValidateMixin from '~~/mixins/deployValidateMixin'
import rejoinerInsertPageMixin from '~~/mixins/rejoinerInsertPageMixin'

const width = 925
const ratio = 0.56349

export default {
  name: 'DefaultLayout',

  components: {
    LazyHydrate,
    AuthModal: hydrateWhenIdle(() => import('~~/components/Auth/AuthModal')),
    VideoModal: hydrateWhenIdle(() =>
      import('~~/components/Common/VideoModal')
    ),
    SearchModal: hydrateWhenIdle(() =>
      import('~~/components/modals/SearchModal.vue')
    ),
    UserMenuMobile: hydrateWhenVisible(() =>
      import(
        '~~/components/Layout/TopHeader/TopHeaderSecondLine/UserMenu/UserMenuMobile'
      )
    ),
    TopHeaderComponent: hydrateWhenIdle(() =>
      import('~~/components/Layout/TopHeader/TopHeader')
    ),
    MainMenu: () => import('~~/components/Layout/MainMenu/MainMenu'),
    ReviewsComponent: hydrateWhenVisible(
      () => import('~~/components/HomePage/Reviews'),
      { observerOptions: { rootMargin: '200px' } }
    ),
    FooterComponent: () => import('~~/components/Layout/Footer/Footer')
    // AuthModalAllSites: hydrateWhenIdle(() =>
    //   import('~~/components/Auth/AllSites/AuthModal')
    // )
  },

  mixins: [listsSyncMixin, deployValidateMixin, rejoinerInsertPageMixin],

  data: () => ({
    lastScrollTop: Number.POSITIVE_INFINITY,
    showUserMenu: false,
    modalName: 'create-process-video',
    modalOptions: {
      autoplay: true,
      controls: true,
      muted: false,
      width,
      height: width * ratio,
      source:
        'https://video.thenaturalsapphirecompany.com/f31bb118-1462-4df6-9d33-b4b17c20ed97/hls/design-your-own.m3u8'
    }
    // renderAuthModalAllSites: false
  }),

  computed: {
    ...mapState({
      hide: (state) => state.app.mobileHeaderHide
    }),

    ...mapGetters({
      shippingCountry: 'cart/shippingCountry'
    }),

    showReviews() {
      const pages = ['cart', 'financing', 'extend-protection-plan']
      return !pages.includes(normRouteName(this.$route.name))
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollHandler, false)
    this.$root.$off('loading-start', this.startLoading)
    this.$root.$off('loading-stop', this.finishLoading)
    this.$root.$off('calc-view-port-units', this.getViewPortUnits)
  },

  mounted() {
    if (this.$nuxt.layoutName === 'default') {
      this.mountedLayoutInit()
      if (this.$device.isMobile)
        window.addEventListener('scroll', this.onScrollHandler, false)
      return
    }
    this.mountedLayoutInit()
  },

  methods: {
    ...mapActions({
      setMobileHeaderVisibility: 'app/setMobileHeaderVisibility',
      setShippingBillingCountry: 'cart/setShippingBillingCountry'
    }),

    async mountedLayoutInit() {
      this.$root.$once('store-restored', this.onRestoreStore)
      this.$root.$on('loading-start', this.startLoading)
      this.$root.$on('loading-stop', this.finishLoading)
      this.$root.$on('calc-view-port-units', this.getViewPortUnits)
      this.getViewPortUnits()
      await this.$microsoftClarity.insert()
      await this.$modernizr.insert()
      this.applyStoreSubscriptions()
      if (
        ['emerald'].includes(this.$site.name) &&
        this.shippingCountry === 'US'
      )
        this.$googleBadge.insert()
      this.$vueGTag.insert(() => {
        this.$root.$emit('gtag-initialized')
      })
      this.$userlike.insert()
      this.$lazyload.insert(() => {
        this.$root.$emit('lazy-loaded')
      })
      if (this.$device.isMobile)
        window.addEventListener('scroll', this.onScrollHandler, false)
      if (!process.env.isDev) {
        this.$analytics.insert()
      }
      this.$chargeAfter.insert()
      await this.rejoinerInsert()
    },

    onRestoreStore() {
      if (this.$cookies.get('country-auto-set')) return // prevent multiple times country change
      this.setShippingBillingCountry()
      this.$cookies.set('country-auto-set', true, {
        maxAge: 60 * 60 * 24,
        ...cookiesOpts
      })
    },

    startLoading() {
      this.$nuxt.$loading.start()
    },

    finishLoading() {
      this.$nuxt.$loading.finish()
    },

    getViewPortUnits() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },

    onCloseHandler() {
      this.showUserMenu = false
    },

    onScrollHandler() {
      const startOffset = 180 // pixels
      const upCallsDelay = 20 // scroll up events calls count
      const downCallsDelay = 20 // scroll down events calls count
      const st = window.scrollY || document.documentElement.scrollTop
      const isInHideArea = st > startOffset

      if (this.lastScrollTop > st) {
        this.downCalls = 0
        this.upCalls++
      } else {
        this.upCalls = 0
        this.downCalls++
      }

      const isHide =
        (this.hide && this.upCalls < upCallsDelay) ||
        (!this.hide && this.downCalls > downCallsDelay)

      this.setMobileHeaderVisibility(isInHideArea && isHide)
      this.lastScrollTop = st <= 0 ? 0 : st
    },

    applyStoreSubscriptions() {
      subscriptions.call(this)
    },

    rejoinerInsert() {
      return new Promise((resolve) => {
        this.$rejoiner.insert(() => {
          resolve()
        })
      })
    }
  }
}
</script>

<style>
html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

/* purgecss ignore */
div[id^='button--userlike-'] > div {
  z-index: 1999 !important;
}
</style>

<style lang="sass" scoped>
.site-wrapper
  flex-direction: column
  min-height: 100vh
  .page
    +mobile
      margin-top: $headerHeight
</style>
