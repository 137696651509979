import { mapGetters, mapState } from 'vuex'
import get from 'lodash/get'
import Vue from 'vue'
import debounce from 'lodash/debounce'
import { getDetailsPath } from '../utils/utils.js'
import { domainsMap } from '../utils/definitions/defaults.js'
import {
  addPageView,
  addRealPageView,
  setCustomerData,
  setCartData
} from '~~/utils/rejoiner/createObjectPayload.js'
import { createTrackObject } from '~~/utils/rejoiner/sendObjects.js'

export default {
  data: () => ({
    listName: 'cart'
  }),

  computed: {
    ...mapState({
      cartSharedItemsFetched: (state) => state.cart.sharedItemsFetched,
      compareSharedItemsFetched: (state) =>
        state.compareList.sharedItemsFetched,
      favoritesSharedItemsFetched: (state) =>
        state.favorites.sharedItemsFetched,
      cartShareHash: (state) => state.cart.shareHash,
      compareShareHash: (state) => state.compareList.shareHash,
      favoritesShareHash: (state) => state.favorites.shareHash
    }),
    ...mapGetters({
      totalPrice: 'cart/getTotal',
      fetched: 'fetched',
      // cartItemsHash: 'cart/itemsHash',
      customItem: 'customItem/customItem',
      productDetails: 'productDetails/productDetails',
      selectedOptions: 'productDetails/selectedOptions'
    }),

    shareHash() {
      return this[`${this.listName}ShareHash`]
    },

    isCartPage() {
      return this.$route.name.includes('cart-index')
    },

    startSendCartData() {
      if (!this.fetched) return false // persistent local storage not applied
      if (this.isCartPage) return this[`${this.listName}SharedItemsFetched`]
      return true
    }
  },

  watch: {
    shareHash(hash) {
      this.updateCardData(hash)
    },

    totalPrice(price) {
      this.updateCardData(price)
    }
  },

  beforeDestroy() {
    this.$root.$off('login-complete', this.setCustomerEmail)
    Vue.prototype.$bus.$off('rj-clear-cart-data', this.clearCartData)
    Vue.prototype.$bus.$off('rj-set-cart-item', this.setCartItem)
    Vue.prototype.$bus.$off('rj-remove-cart-item', this.removeCartItem)
    this.$root.$off('routeChanged', this.addRealPageView)
    this.$root.$off('routeChanged', this.addTrackProductView)
    this.$root.$off('rj-add-page-view', this.addPageView)
    this.$root.$off('rj-set-customer-data', this.setCustomerData)
    this.$root.$off('rj-set-customer-email', this.setCustomerEmail)
    this.$root.$off('rj-clear-cart-data', this.clearCartData)
    this.$root.$off('rj-set-cart-data', this.setCartData)
    this.$root.$off('rj-send-conversion', this.sendConversion)
    this.$root.$off('rj-subscribe-to-list', this.addSubscribeToListObject)
  },

  mounted() {
    this.setCustomerEmail()
    this.addRealPageView(this.$route)
    this.addTrackProductView(this.$route)
    this.$root.$on('login-complete', this.setCustomerEmail)
    Vue.prototype.$bus.$on('rj-clear-cart-data', this.clearCartData)
    Vue.prototype.$bus.$on('rj-set-cart-item', this.setCartItem)
    Vue.prototype.$bus.$on('rj-remove-cart-item', this.removeCartItem)
    this.$root.$on('routeChanged', this.addRealPageView)
    this.$root.$on('routeChanged', this.addTrackProductView)
    this.$root.$on('rj-add-page-view', this.addPageView)
    this.$root.$on('rj-set-customer-data', this.setCustomerData)
    this.$root.$on('rj-set-customer-email', this.setCustomerEmail)
    this.$root.$on('rj-clear-cart-data', this.clearCartData)
    this.$root.$on('rj-set-cart-data', this.setCartData)
    this.$root.$on('rj-send-conversion', this.sendConversion)
    this.$root.$on('rj-subscribe-to-list', this.addSubscribeToListObject)
  },

  methods: {
    updateCardData(value) {
      if (!this.startSendCartData) return
      this.$nextTick(() => {
        if (value) {
          this.$root.$emit('rj-set-cart-data', this.$store.getters)
          return
        }
        this.$root.$emit('rj-clear-cart-data')
      })
    },

    setCustomerEmail(params) {
      const email = params?.email || this.$auth.user?.email
      if (!email) return
      const query = ['setCustomerEmail', { email }]
      this.$rejoiner.push(query)
    },

    addPageView(params) {
      const query = ['addPageView', addPageView(params)]
      this.$rejoiner.push(query)
    },

    clearCartData(params) {
      this.$rejoiner.push(['clearCartData'])
    },

    addRealPageView(newRoute, oldRoute) {
      if (newRoute?.path === oldRoute?.path) return
      const query = ['addPageView', addRealPageView(newRoute)]
      this.$rejoiner.push(query)
    },

    setCustomerData(params) {
      const query = ['setCustomerData', setCustomerData(params)]
      this.$rejoiner.push(query)
    },

    setCartItem(item) {
      const query = ['setCartItem', this.createObjTrackView(item)]
      this.$rejoiner.push(query)
    },

    removeCartItem(item) {
      const query = [
        'removeCartItem',
        { product_id: this.createObjTrackView(item).product_id }
      ]
      this.$rejoiner.push(query)
    },

    setCartData: debounce(function (getters) {
      if (!getters['cart/allListItems'].length) return
      const query = ['setCartData', setCartData(getters)]
      this.$rejoiner.push(query)
    }, 2000),

    sendConversion(params) {
      const cartData = setCartData(params, true)
      const cartItems = params['cart/orderItems'].map((item) =>
        this.createObjTrackView(item)
      )
      const query = [
        'sendConversion',
        { cart_data: cartData, cart_items: cartItems }
      ]
      this.$rejoiner.push(query)
    },

    addTrackProductView(newRoute, oldRoute) {
      setTimeout(() => {
        if (newRoute?.path === oldRoute?.path) return
        const pageType = get(this.$refs, 'page.$el.dataset.pageType', null)
        if (!['details', 'review'].includes(pageType)) return
        const isSoldStatuses = [2, 3]
        if (pageType === 'details') {
          if (this.productDetails.category.includes('Setting_')) return
          if (isSoldStatuses.includes(this.productDetails.itemStatus.status))
            return
        }
        if (pageType === 'review') {
          if (
            isSoldStatuses.includes(
              this.customItem.customStone.itemStatus.status
            )
          )
            return
        }
        const query = ['trackProductView', this.createObjTrackView()]
        this.$rejoiner.push(query)
      }, 10)
    },

    createObjTrackView(item = null) {
      if (item) {
        return createTrackObject(item, {
          item_qty: 1,
          // window?.location.origin + window?.location.pathname
          product_url: this.getItemDetailsUrl(item),
          selectedOptions: this.selectedOptions
        })
      }

      const pageType = get(this.$refs, 'page.$el.dataset.pageType', null)

      if (pageType === 'details') {
        return createTrackObject(
          this.productDetails,
          this.additionalInfo('details')
        )
      }
      if (pageType === 'review') {
        return createTrackObject(this.customItem, this.additionalInfo('review'))
      }
    },

    getItemDetailsUrl(item) {
      const domain = `https://${domainsMap[process.env.siteName].prodDomain}`
      return domain + getDetailsPath(item, this.$route)
    },

    additionalInfo(formObject = null) {
      return {
        formObject,
        typePage: this.$options.pageType,
        product_url: window?.location.origin + window?.location.pathname,
        siteName: process.env.siteName,
        selectedOptions: this.selectedOptions
      }
    },

    addSubscribeToListObject(
      email = '',
      listId = process.env.rejoinerSubscribeListId
    ) {
      if (!email) return
      const query = {
        email,
        list_id: listId
      }
      this.$rejoiner.push(['subscribeToList', query])
    }
  }
}
